import React, { Component } from 'react'
import { Button, message } from 'antd'
import 'katex/dist/katex.min.css'
import { InlineMath, BlockMath } from 'react-katex'
import './index.scss'
import { timeFormat } from '../utils/utils'
import { getInterviewTime, uploadImg, uploadInterviewImg, checkEnterStatus } from '../utils/api'

let video
class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            startTime: null,
            techTime: null,
            showVideo: false,
            showStart: false,
            upImg: null,
            round: 1,
            exam_id: null,
            position_type: null,
            position: null,
            post: null,
        }
    }
    componentDidMount() {
        video = document.getElementById("video")
        let str = this.props.location.search.split('=')
        let id = str[1].split('&')[0]
        this.setState({
            id,
        })
        getInterviewTime({ id }).then(res => {
            this.setState({
                startTime: timeFormat(res.data.start_time, '-', '%H:%M'),
                techTime: timeFormat(res.data.tech_time, '-', '%H:%M'),
                round: res.data.round,
                exam_id: res.data.exam_id,
                position_type: res.data.position_type,
                position: res.data.position,
                post: res.data.post
            })
        })
    }

    getMedia() {
        let constraints = {
            video: { width: 500, height: 300 },
            audio: true
        }
        let promise = navigator.mediaDevices.getUserMedia(constraints)
        promise.then(function (MediaStream) {
            video.srcObject = MediaStream
            video.play()
        }).catch(function (PermissionDeniedError) {
            console.log(PermissionDeniedError)
        })
        this.setState({
            showVideo: true
        })
    }

    takePhoto() {
        const cur = this
        // 获得Canvas对象
        let canvas = document.getElementById("canvas")
        let ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, 500, 300)
        var imgData = canvas.toDataURL()
        uploadImg({
            imgData: imgData,
            name: 'name_img',
        }).then(res => {
            cur.setState({
                upImg: res.data
            })
        })
    }

    startExam = () => {
        if (!this.state.upImg) {
            message.error('请先进行拍照！')
            return
        }
        let round = this.state.round
        let nowTime = new Date().getTime()
        let startTime = round === 1 ? new Date(this.state.startTime.replace(new RegExp(/-/gm), "/")).getTime() : new Date(this.state.techTime.replace(new RegExp(/-/gm), "/")).getTime()

        if (nowTime > startTime) {
            uploadInterviewImg({
                img: this.state.upImg,
                id: this.state.exam_id,
                first_enter: timeFormat(new Date(), '-', '%H:%M:%S')
            })
            checkEnterStatus({
                id: this.state.exam_id,
                times: 1,
                round
            })
                .then(res => {
                    if (res.code == 1) {
                        this.props.history.push('/login')
                        message.error(res.msg)
                    } else {
                        this.fullScreen()
                        if (this.state.position_type == 2) {
                            this.props.history.push('/it?id=' + this.state.exam_id + '&round=' + this.state.round)
                            return
                        }
                        // 增加alpha试卷
                        if (this.state.post == 14) {
                            this.props.history.push('/alpha?id=' + this.state.exam_id + '&round=' + this.state.round)
                            return
                        }
                        if (this.state.position == '交易研究员' && this.state.position_type == 3) {
                            this.props.history.push('/twap?id=' + this.state.exam_id + '&round=' + this.state.round)
                            return
                        }
                        if (round === 1) {
                            this.props.history.push('/choice?id=' + this.state.exam_id + '&round=' + this.state.round)
                        } else if (round === 2) {
                            this.props.history.push('/exam?id=' + this.state.exam_id + '&round=' + this.state.round)
                        }
                    }
                })
        } else {
            message.error('考试还未开始')
            this.setState({
                showStart: true
            })
        }
    }

    fullScreen = () => {
        if (!this.state.isFullScreen) {
            this.requestFullScreen()
        } else {
            this.exitFullscreen()
        }
    }

    requestFullScreen = () => {
        var de = document.documentElement
        if (de.requestFullscreen) {
            de.requestFullscreen()
        } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen()
        } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen()
        }
    }

    render() {
        const { startTime, techTime, showVideo, showStart, round, position_type, position, post } = this.state
        return (
            <div className='index-contanier'>
                <p className='large'>希格斯欢迎您</p>
                <p className='large'>笔试题开始时间</p>
                <p className='large'>{round === 1 ? startTime : techTime}</p>
                <p className='small' >1. 请点击下方“开启摄像头”按钮，同意开启麦克风（摄像头）测试摄像功能，</p>
                <p className='small'>保证人脸在摄像区域，并点击“进行拍照”按钮拍照。</p>
                <p className='x'><i>若摄像头无法正常开启可尝试升级谷歌浏览器或使用最新版Edge浏览器以及火狐浏览器。</i> </p>

                {/* <video id="video" autoplay="" ></video> */}
                {/* <Button type="primary" style={{ marginBottom: 14, marginTop: 30, marginRight: 100 }} onClick={this.openCamera.bind(this)} >开启摄像头</Button>
                <Button type="primary" style={{ marginBottom: 14, marginTop: 30 }} onClick={this.takePhoto.bind(this)} >进行拍照</Button> */}
                <div className='btn-wrap'>
                    <Button type="primary" style={{ marginBottom: 14, marginTop: 30 }} onClick={this.getMedia.bind(this)} >开启摄像头</Button>
                    <Button type="primary" style={{ marginBottom: 14, marginTop: 30 }} onClick={this.takePhoto.bind(this)} >进行拍照</Button>
                </div>
                <video id="video" width="500px" height="300px" autoPlay="autoplay"></video>

                <canvas id="canvas" width="500px" height="300px"></canvas>
                <p className='small'>2. 拍照成功后，点击 "开始答题"按钮，进入答题页面</p>
                {showStart && <p className='large'>考试还未开始，请耐心等待</p>}
                <Button type="primary" style={{ marginBottom: 14, marginTop: 30 }} onClick={this.startExam.bind(this)} >进入考试</Button>

                {/* IT考试说明 */}
                {position_type == 2 ?
                    <div className="wrap">
                        <p className='red'>考试说明</p>
                        <p className='red'>1. 本次考试为闭卷形式，请保持良好的考试纪律。在答题过程中请保持人脸位置始终位于软件摄像区域内， 请勿切换浏览器或使用其他软件，请勿刷新页面或者重置页面，如果违反包括但不限于上述情况，将直接影响或者取消考试成绩！</p>
                    </div>
                    : <>
                        <div>参考示例：</div>
                        <BlockMath>{String.raw`\sum_{i=0}^{n}{(x_i+y_i)}
                    \prod_{i=0}^{n}{x_i\cdot{y_i}}
                    \lim_{x\to0}\frac{sinx}{x}
                    \int_{a}^{b}{sinx}dx`}</BlockMath>
                        <BlockMath >{String.raw`f(x) = 
                    \begin{cases}
                        \dfrac{\cos{x}}{x+\sin{x}} & x \geq 0 \\
                        ax^2+bx+c & x \leq 0
                    \end{cases}`}</BlockMath>
                        <div><i>提示：请查看以上公式是否可以正确显示，如不可以正确显示请及时更换或升级浏览器</i> </div>

                        {/* position */}
                        {/* {round === 1 && <div className="wrap">
                            <p className='red'>考试说明</p>
                            <p className='red'>1. 本面试题一共3小时，分A B 两个部分，A部分为15道选择题，B部分为5道解答题，通过A部分考试才可进入B部分，超出作答时间则自动提交。</p>
                            <p className='red'>2. 本次考试为闭卷形式，请保持良好的考试纪律。在答题过程中请保持人脸位置始终位于软件摄像区域内， 请勿切换浏览器或使用其他软件，请勿刷新页面或者重置页面，如果违反包括但不限于上述情况，将直接影响或者取消考试成绩！</p>
                        </div>} */}

                        {/* 交易研究员 */}
                        {position === '交易风控-TWAP' ?
                            <>
                                <div className="wrap">
                                    <p className='red'>考试说明</p>
                                    {/* ，A部分最长作答时间为1.5小时 */}
                                    <p className='red'>1. 本面试题一共2小时，分A B C两个部分，A部分为选择题，B部分为python解答题，C部分为数学解答题，超出作答时间则自动提交。</p>
                                    <p className='red'>2. 本次考试为闭卷形式，请保持良好的考试纪律。在答题过程中请保持人脸位置始终位于软件摄像区域内， 请勿切换浏览器或使用其他软件，请勿刷新页面或者重置页面，如果违反包括但不限于上述情况，将直接影响或者取消考试成绩！</p>
                                </div>
                            </> :
                            <>
                                {post == 13 ? <>
                                    <div className="wrap">
                                        <p className='red'>考试说明</p>
                                        {/* ，A部分最长作答时间为1.5小时 */}
                                        <p className='red'>1、本试题一共1个小时，分A、B两个部分：A部分为10道选择题，B部分为简答题、解答题和数学题。请合理安排时间，超出时间则自动提交。</p>
                                        <p className='red'>2、本次考试为闭卷形式，请保持良好的考试纪律。在答题过程中请保持人脸位置始终位于软件摄像区域内， 请勿切换浏览器或使用其他软件，请勿刷新页面或者重置页面，如果违反包括但不限于上述情况，将直接影响或者取消考试成绩</p>
                                    </div>
                                </> :
                                    <>
                                        {post == 14 ? <>
                                            <div className="wrap">
                                                <p className='red'>考试说明</p>
                                                <p className='red'>1、本试题一共3个小时，分为选择题，简答题、解答题。请合理安排时间，超出时间则自动提交。</p>
                                                <p className='red'>2、本次考试为闭卷形式，请保持良好的考试纪律。在答题过程中请保持人脸位置始终位于软件摄像区域内， 请勿切换浏览器或使用其他软件，请勿刷新页面或者重置页面，如果违反包括但不限于上述情况，将直接影响或者取消考试成绩</p>
                                            </div>
                                        </> :
                                            <>
                                                {round === 1 && <div className="wrap">
                                                    <p className='red'>考试说明</p>
                                                    {/* ，A部分最长作答时间为1.5小时 */}
                                                    <p className='red'>1. 本面试题一共3小时，分A B 两个部分，A部分为15道选择题，B部分为5道解答题，通过A部分考试才可进入B部分，超出作答时间则自动提交。</p>
                                                    <p className='red'>2. 本次考试为闭卷形式，请保持良好的考试纪律。在答题过程中请保持人脸位置始终位于软件摄像区域内， 请勿切换浏览器或使用其他软件，请勿刷新页面或者重置页面，如果违反包括但不限于上述情况，将直接影响或者取消考试成绩！</p>
                                                </div>}
                                                {round === 2 && <div className="wrap">
                                                    <p className='red'>考试说明</p>
                                                    {/* ，A部分最长作答时间为1.5小时 */}
                                                    <p className='red'>1. 本面试题一共1小时，一共有7道解答题，如超出作答时间则自动提交。</p>
                                                    <p className='red'>2. 本次考试为闭卷形式，请保持良好的考试纪律。在答题过程中请保持人脸位置始终位于软件摄像区域内， 请勿切换浏览器或使用其他软件，请勿刷新页面或者重置页面，如果违反包括但不限于上述情况，将直接影响或者取消考试成绩！</p>
                                                </div>
                                                }
                                            </>}
                                    </>
                                }
                            </>
                        }
                    </>}
            </div>
        )
    }
}

export default Index

