import React, { Component } from 'react'
import { Form, Button, Radio, message, Modal, Result, Input } from 'antd';
import 'katex/dist/katex.min.css';
import './index.scss'

import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/mode/sql/sql';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/elegant.css';
import 'codemirror/theme/3024-night.css';
import 'codemirror/theme/3024-day.css';
import { SmileOutlined, UploadOutlined } from '@ant-design/icons';
import choice from './choice';
import {
    getInterviewTime, uploadImg, uploadInterviewImg, checkEnterStatus,
    addCheatsTimes, addCheats, examFinish,
    getItQuestion, submitItAnswer
} from '../utils/api'

const options = {
    lineNumbers: true,
    extraKeys: { "Ctrl": "autocomplete" },
    theme: "3024-day",
    readOnly: true
}
const options2 = {
    lineNumbers: true,
    extraKeys: { "Ctrl": "autocomplete" },
    theme: "3024-night",
    height: '300px'
}
const options3 = {
    // lineNumbers: true,
    extraKeys: { "Ctrl": "autocomplete" },
    theme: "3024-day",
    readOnly: true
}
let video
let interId
let takePh

class It extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            round: 1,
            choice: [],
            question: [],
            fill: [],
            choiceAList: [],
            showConfirm: false,
            showFinish: false,
            during: 0,
            visible: false,
        }
    }

    componentDidMount() {
        const cur = this
        video = document.getElementById("video")
        if (process.env.NODE_ENV == 'production') {
            this.getMedia()
        }
        let str = this.props.location.search.split('=')
        let id = str[1].split('&')[0]
        let round = str[2]
        checkEnterStatus({
            id,
            round
        }).then(res => {
            if (res.code == 1) {
                this.props.history.push('/login')
                message.error(res.msg)
            }
        })

        getItQuestion({ id })
            .then(res => {
                let c = res.data.choice
                let q = res.data.q
                let f = res.data.fill
                let temp = JSON.parse(res.data.temp)
                let tempFArr = []
                let tempQArr = []
                if (temp) {
                    tempFArr = temp?.fArr
                    tempQArr = temp?.qArr
                }
                if (c) {
                    for (let i = 0; i < c.length; i++) {
                        let option_arr = c[i].option_list.split('@@')
                        c[i].arr = c[i].stem.split('```')
                        c[i].option_arr = option_arr
                    }
                }

                if (q) {
                    for (let i = 0; i < q.length; i++) {
                        q[i].arr = q[i].stem.split('```')
                        if (tempQArr[0]) {
                            q[i].is_code = tempQArr[i].is_code
                            q[i].answers = tempQArr[i].answers
                        } else {
                            q[i].is_code = 0
                            q[i].answers = null
                        }
                    }
                }

                if (f) {
                    for (let i = 0; i < f.length; i++) {
                        f[i].arr = f[i].stem.split('```')
                        if (temp) {
                            f[i].is_code = tempFArr[i].is_code
                            f[i].answers = tempFArr[i].answers
                        } else {
                            f[i].is_code = 0
                            f[i].answers = null
                        }
                    }
                }
                if (temp) {
                    let tempD = temp.d
                    if (tempD) {
                        for (let i = 0; i < tempD.length; i++) {
                            c[i].ans = tempD[i]
                        }
                        this.setState({
                            choiceAList: tempD,
                        })
                    }
                }
                this.setState({
                    choice: c ? c : [],
                    question: q ? q : [],
                    fill: f ? f : [],
                })

            })
        this.setState({
            id,
            round
        })

        if (process.env.NODE_ENV == 'production') {
            window.addEventListener('resize', this.onResize)
            window.onblur = function () {
                addCheatsTimes({ id })
                cur.setState({
                    visible: true,
                    cheat: cur.state.cheat + 1
                })
            }
        }
        let times
        getInterviewTime({ id }).then(res => {
            times = (round == 1 ? new Date(res.data.start_time).getTime() : new Date(res.data.tech_time).getTime())
        })
        interId = setInterval(() => {
            this.setState({
                time: (new Date()).toLocaleString(),
            })
            this.countTime(times)
        }, 1000)
        takePh = setInterval(() => {
            let canvas = document.getElementById("canvas");
            let ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, 500, 300);
            var imgData = canvas.toDataURL();
            uploadImg({
                imgData: imgData,
                name: 'name_img',
            }).then(res => {
                uploadInterviewImg({
                    img: res.data,
                    id: this.state.id
                })
            })
        }, 1000 * 60 * 5)
    }

    componentWillUnmount() {
        clearInterval(interId)
        clearInterval(takePh)
    }

    getMedia() {
        let constraints = {
            video: { width: 500, height: 300 },
            audio: true
        }
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise.then(function (MediaStream) {
            video.srcObject = MediaStream;
            video.play();
        }).catch(function (PermissionDeniedError) {
            console.log(PermissionDeniedError);
        })
        this.setState({
            showVideo: true
        })
    }

    onResize = () => {
        if (this.state.cheat == -1) {
            this.setState({
                cheat: 0
            })
        } else {
            if (this.state.visible == false) {
                addCheatsTimes({
                    id: this.state.id
                })
                this.setState({
                    visible: true,
                    cheat: this.state.cheat + 1
                })
            }
        }
    }
    countTime(time) {
        const cur = this
        let getNowTime = new Date()
        let t = getNowTime.getTime() - time
        let hour = Math.floor((t / 3600000) % 24)
        let min = Math.floor((t / 60000) % 60)
        let sec = Math.floor((t / 1000) % 60)
        let round = this.state.round
        if (round == 1) {
            if (min % 5 == 0 && sec % 30 == 0) {
                let d = this.state.choiceAList
                let f = this.state.fill
                let q = this.state.question
                let fArr = []
                let qArr = []
                for (let i = 0; i < f.length; i++) {
                    let obj = {
                        q_id: f[i].id,
                        is_code: f[i].is_code,
                        answers: f[i].answers
                    }
                    fArr.push(obj)
                }
                for (let i = 0; i < q.length; i++) {
                    let obj = {
                        q_id: q[i].id,
                        is_code: q[i].is_code,
                        answers: q[i].answers
                    }
                    qArr.push(obj)
                }
                let a = {
                    id: this.state.id,
                    d,
                    fArr,
                    qArr,
                    temp: true
                }
                submitItAnswer({ data: a })
                return
            }
            if (hour >= 2) {
                let d = this.state.choiceAList
                let f = this.state.fill
                let q = this.state.question
                let fArr = []
                let qArr = []
                for (let i = 0; i < f.length; i++) {
                    let obj = {
                        q_id: f[i].id,
                        is_code: f[i].is_code,
                        answers: f[i].answers
                    }
                    fArr.push(obj)
                }
                for (let i = 0; i < q.length; i++) {
                    let obj = {
                        q_id: q[i].id,
                        is_code: q[i].is_code,
                        answers: q[i].answers
                    }
                    qArr.push(obj)
                }
                let a = {
                    id: this.state.id,
                    d,
                    fArr,
                    qArr
                }
                submitItAnswer({ data: a })
                    .then(res => {
                        if (res.code == 1) {
                            message.error('你已经上传过答案，请退出考试系统！');
                        } else {
                            cur.setState({
                                showFinish: true
                            })
                        }
                    })
                addCheats({
                    times: this.state.cheat == -1 ? 0 : this.state.cheat,
                    id: this.state.id
                })
                examFinish({
                    time: this.state.during,
                    id: this.state.id,
                    type: 1
                })
                clearInterval(interId)
            }
            if (hour) {
                this.setState({
                    during: hour + '时' + min + '分' + sec + '秒'
                })
            } else {
                this.setState({
                    during: min + '分' + sec + '秒'
                })
            }
        }
    }

    onChangeRadio = (e, index) => {
        let a = this.state.choiceAList
        a[index] = e.target.value
        this.setState({
            choiceAList: a
        })
    }

    onChangeInputRadio = (e, index, name) => {
        let f = this.state[name]
        f[index].is_code = e.target.value
        this.setState({
            [name]: f
        })
    }

    handleInput = (e, index, name) => {
        let f = this.state[name]
        f[index].answers = e.target.value
        this.setState({
            [name]: f
        })
    }

    ChangeCode = (e, index, name) => {
        let f = this.state[name]
        f[index].answers = e
        this.setState({
            [name]: f
        })
    }

    submit = () => {
        this.setState({
            showConfirm: true,
        });
    }

    confirmOk = () => {
        const cur = this
        let d = this.state.choiceAList
        let f = this.state.fill
        let q = this.state.question

        let fArr = []
        let qArr = []
        let flag = true

        if (d.length != this.state.choice.length) flag = false

        for (let i = 0; i < d.length; i++) {
            if (!d[i]) {
                flag = false
            }
        }

        for (let i = 0; i < f.length; i++) {
            if (!f[i].answers) {
                flag = false
            }
            let obj = {
                q_id: f[i].id,
                is_code: f[i].is_code,
                answers: f[i].answers
            }
            fArr.push(obj)
        }

        for (let i = 0; i < q.length; i++) {
            if (!q[i].answers) {
                flag = false
            }
            let obj = {
                q_id: q[i].id,
                is_code: q[i].is_code,
                answers: q[i].answers
            }
            qArr.push(obj)
        }
        if (flag) {
            let a = {
                id: this.state.id,
                d,
                fArr,
                qArr
            }
            submitItAnswer({ data: a })
                .then(res => {
                    if (res.code == 1) {
                        message.error('你已经上传过答案，请退出考试系统！');
                    } else {
                        cur.setState({
                            showFinish: true
                        })
                    }
                })
            addCheats({
                times: this.state.cheat == -1 ? 0 : this.state.cheat,
                id: this.state.id
            })
            examFinish({
                time: this.state.during,
                id: this.state.id,
                type: 1
            })
        } else {
            this.setState({
                showConfirm: false
            })
            message.error('请检查是否全部题目都已作答！');
        }
    }
    confirmCancel = () => {
        this.setState({
            showConfirm: false,
        });
    }
    handleOk = e => {
        this.setState({
            visible: false,
        });
    }
    handleCancel = e => {
        this.setState({
            visible: false,
        });
    }
    render() {
        const { choice, question, fill, showFinish } = this.state
        return (
            <div className='contanier'>
                {showFinish ? <Result
                    icon={<SmileOutlined />}
                    title="试卷已提交成功，感谢您的参与，若有问题请联系recruitment@higgsasset.com "
                /> :
                    <Form layout="vertical" hideRequiredMark style={{ textAlign: 'left', marginLeft: 30, paddingBottom: 100 }} >
                        <video id="video" className='vidieos' width="500px" height="300px" autoPlay="autoplay"></video>
                        <canvas id="canvas" className='canvas' width="500px" height="300px"></canvas>
                        {/* 考试计时 */}
                        <div className='time'>{this.state.time}</div>
                        <div className='time'>考试已经开始：{this.state.during} </div>
                        {/* 题目内容 */}

                        {choice[0] && <div style={{ fontSize: 28, fontWeight: 700 }}>选择题：</div>}
                        {choice.map((item, index) => {
                            return (
                                <div key={index}>
                                    <span>{index + 1}. (分值：{item.point}
                                        ) </span>
                                    <span>
                                        {item.arr.map((item, inx) => {
                                            if (inx % 2 == 0) {
                                                return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                            } else {
                                                return <CodeMirror
                                                    value={item}
                                                    options={options}
                                                />
                                            }
                                        })}
                                    </span>
                                    <div className='options'>
                                        <Radio.Group defaultValue={item.ans} onChange={(e) => this.onChangeRadio(e, index)} >
                                            {item.option_arr && item.option_arr.map((info, ind) => {
                                                return (
                                                    <Radio key={ind} value={ind + 1}>
                                                        <span style={{ position: 'relative', top: 8 }}> {ind == 0 ? 'A' : (ind == 1 ? 'B' : (ind == 2 ? 'C' : 'D'))}:
                                                            <CodeMirror
                                                                value={info}
                                                                options={options3}
                                                            />
                                                        </span>
                                                    </Radio>
                                                )
                                            })}
                                        </Radio.Group>
                                    </div>
                                </div>
                            )
                        })}

                        {fill[0] && <div style={{ fontSize: 28, fontWeight: 700 }}>填空：</div>}
                        {fill.map((item, index) => {
                            return (
                                <div key={index} style={{ marginBottom: 30 }}>
                                    <span>{index + 1}.  (分值：{item.point}
                                        {/* 难度：{item.difficulty == 1 ? '难' : (item.difficulty == 2 ? '中' : '易')} 科目：{item.type == 11 ? 'C++' : (item.type == 12 ? '操作系统' : (item.type == 13 ? '网络' : '算法'))} */}
                                        ) </span>
                                    <span>
                                        {item.arr.map((item, inx) => {
                                            if (inx % 2 == 0) {
                                                return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                            } else {
                                                return <CodeMirror
                                                    value={item}
                                                    // onChange={code => this.ChangeCode(code)}
                                                    options={options}
                                                />
                                            }
                                        })}
                                    </span>
                                    <div>
                                        作答：
                                        <Radio.Group defaultValue={item.is_code} onChange={(e) => this.onChangeInputRadio(e, index, 'fill')}>
                                            <Radio value={0} >
                                                <span style={{ position: 'relative', top: 8 }}>输入框</span>
                                            </Radio>
                                            <Radio value={1}>
                                                <span style={{ position: 'relative', top: 8 }}>编码</span>
                                            </Radio>
                                        </Radio.Group>
                                        {item.is_code == 0 ?
                                            <Input.TextArea rows={4} defaultValue={item.answers} placeholder="请输入答案" onChange={(e) => this.handleInput(e, index, 'fill')} /> :
                                            <CodeMirror
                                                value={item.answers}
                                                onChange={code => this.ChangeCode(code, index, 'fill')}
                                                options={options2}
                                            />}
                                    </div>
                                </div>
                            )
                        })}

                        {question[0] && <div style={{ fontSize: 28, fontWeight: 700 }}>解答：</div>}
                        {question.map((item, index) => {
                            return (
                                <div key={index}>
                                    <span>{index + 1}.  (分值：{item.point}
                                        {/* 难度：{item.difficulty == 1 ? '难' : (item.difficulty == 2 ? '中' : '易')} 科目：{item.type == 11 ? 'C++' : (item.type == 12 ? '操作系统' : (item.type == 13 ? '网络' : '算法'))} */}
                                        )</span>
                                    <span>
                                        {item.arr.map((item, inx) => {
                                            if (inx % 2 == 0) {
                                                return <span dangerouslySetInnerHTML={{ __html: item }}></span>

                                            } else {
                                                return <CodeMirror
                                                    value={item}
                                                    // onChange={code => this.ChangeCode(code)}
                                                    options={options}
                                                />
                                            }
                                        })}
                                    </span>
                                    <div>
                                        作答：
                                        <Radio.Group defaultValue={item.is_code} onChange={(e) => this.onChangeInputRadio(e, index, 'question')}>
                                            <Radio value={0} >
                                                <span style={{ position: 'relative', top: 8 }}>输入框</span>
                                            </Radio>
                                            <Radio value={1}>
                                                <span style={{ position: 'relative', top: 8 }}>编码</span>
                                            </Radio>
                                        </Radio.Group>
                                        {item.is_code == 0 ?
                                            <Input.TextArea rows={4} defaultValue={item.answers} placeholder="请输入答案" onChange={(e) => this.handleInput(e, index, 'question')} /> :
                                            <CodeMirror
                                                value={item.answers}
                                                onChange={code => this.ChangeCode(code, index, 'question')}
                                                options={options2}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        <Button type="primary" style={{ marginBottom: 14, marginTop: 20 }} onClick={this.submit.bind(this)} >提交答案</Button>
                        <Modal
                            title="答案提交！"
                            visible={this.state.showConfirm}
                            okText='确认'
                            cancelText='返回继续作答'
                            onOk={this.confirmOk}
                            onCancel={this.confirmCancel}
                        >
                            <p>所有题目只能提交一次，提交之后不可回退或再次修改答案！</p>
                        </Modal>
                    </Form>
                }

                <Modal
                    maskStyle={{ background: '#000' }}
                    title="禁止拖动屏幕"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p> 请在答题界面安心作答！</p>
                </Modal>
            </div>
        );
    }
}

export default It;