//在index.js中引入axios
import axios from 'axios';
//引入qs模块，用来序列化post类型的数据
import QS from 'qs';
import {
    message
} from 'antd'

// const env = process.env.NODE_ENV
const env = 'production' 
let defaultHost = {
    higgsPath: env === 'production' ? 'https://www.higgsasset.com/higgs' : 'http://10.1.1.41:7001/dev'
}

//设置axios基础路径
const service = axios.create({
    baseURL: defaultHost.higgsPath
})
// 请求拦截器
service.interceptors.request.use(config => {
    //设置请求头
    config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }

    config.data = QS.stringify(config.data)
    return config
}, error => {
    return error;
})

// 响应拦截器
service.interceptors.response.use(response => {
    if (response.status) {
        switch (response.status) {
            case 200:
                return response.data;
            case 401:
                //未登录处理方法
                break;
            case 403:
                //token过期处理方法
                break;
            default:
                message.error(response.data.msg)
        }
    } else {
        return response.data;
    }
})
//最后把封装好的axios导出
export default service